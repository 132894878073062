import { HMActionBarNavDropdown } from "@app/products/hm/components/action-bar/nav-dropdown/_index";
import { HMUrl } from "@app/products/hm/config";
import { PremiseContactsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/contacts/_index";
import { PremiseHistoryTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/history/_index";
import { HMPremisesTabMap } from "@app/products/hm/premises/[id]/components/reference-sidebar/map/_index";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { HMPremisesRegisterView } from "@app/products/hm/premises/model";
import {
  colHMPremisesRegister,
  generateHMPremisesRegisterColumnsBySetting,
} from "@app/products/hm/premises/register/config";
import { premisesRoute } from "@app/products/hm/premises/route";
import { HMPremisesBookmark } from "@app/products/hm/premises/util";
import { hmRoute } from "@app/products/hm/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

export const nameOfHMPremisesRegister = nameOfFactory<HMPremisesRegisterView>();
export default observer(() => {
  const { settings } = useCommonCoreStore();

  useCCListViewActionBar({
    title: hmRoute.name,
    leftComponents: [<HMActionBarNavDropdown category={premisesRoute.path} />],
    centerComponents: [
      // #region Implement later
      // <NewPremiseButton />,
      // <MailMergeButtonListView
      //   productType={PRODUCT_TYPE_NUMBER.HealthManager}
      //   dataSetFilter={MAILMERGEDATASET.HealthManager_Premises}
      //   recordType={RECORDTYPE.HealthManager_Premises}
      // />,
      // <CommunicationButtonListView
      //   productType={PRODUCT_TYPE_NUMBER.HealthManager}
      //   recordType={RECORDTYPE.HealthManager_Premises}
      // />,
      // <DeleteButton />,
      // #endregion
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PREMISES_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
        detail={HMPremisesBookmark.getBookmarkDetailDisplayName}
        displayName={HMPremisesBookmark.getBookmarkListViewDetailRecord}
        listViewDetail={() =>
          HMPremisesBookmark.getBookmarkListViewDetail("Register")
        }
        listViewDisplayName={HMPremisesBookmark.getBookmarkDetailDisplayName}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      // { title: "Details", component: <PremiseDetailsTab /> }, Implement later
      {
        title: "Map",
        component: <HMPremisesTabMap />,
      },
      { title: "Contacts", component: <PremiseContactsTab /> },
      {
        title: "History",
        component: <PremiseHistoryTab />,
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={generateHMPremisesRegisterColumnsBySetting({
        settings,
        defaultConfig: colHMPremisesRegister,
      })}
      dataUrl={HMUrl.GET_VIEW_HM_PREMISES_REGISTER}
      primaryField={nameOfHMPremisesRegister("ID")}
      state={{
        sort: [
          {
            field: nameOfHMPremisesRegister("TradingName"),
            dir: "asc",
          },
        ],
      }}
    />
  );
});

import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import { PermitContactsTab } from "@app/products/local-laws/components/sidebar/contacts/_index";
import { PermitDetailsTab } from "@app/products/local-laws/components/sidebar/details/_index";
import { PermitHistoryTab } from "@app/products/local-laws/components/sidebar/history/_index";
import { NewPermitButton } from "@app/products/local-laws/components/toolbar/buttons/new-local-laws/_index";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { colLocalLawsRenewals } from "@app/products/local-laws/config";
import { getLocalLawRenewalsListViewOdata } from "@app/products/local-laws/constant";
import {
  PermitRenewals,
  PermitRenewalsTypes,
} from "@app/products/local-laws/model";
import { llPermitsBookmark } from "@app/products/local-laws/permits/util";
import { PermitIssueRenewalNoticeButton } from "@app/products/local-laws/renewals/requires-renewal-notice/components/buttons/issue-renewal-notice/_index";
import { PermitResetRenewalFeeFlagButton } from "@app/products/local-laws/renewals/requires-renewal-notice/components/buttons/reset-renewal-flag-fee/_index";
import { renewalsRoute } from "@app/products/local-laws/renewals/route";
import { localLawsRoute } from "@app/products/local-laws/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<PermitRenewals>();
const gridRequiresRenewalNoticesId = getUUID();
export default () => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });
  const { listViewDisplayURL, listViewDisplayTitle, recordDisplayURL } =
    llPermitsBookmark("Requires Renewal Notice");

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={renewalsRoute.path} />,
    ],
    centerComponents: [
      <NewPermitButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        dataSetFilter={MAILMERGEDATASET.LLP_Permit}
        recordType={RECORDTYPE.LLP_Permit}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        recordType={RECORDTYPE.LLP_Permit}
        keyField={nameOf("ID")}
        isDisabled={
          !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
        }
      />,
      <DeleteButton
        recordType={RECORDTYPE.LLP_Permit}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        gridId={gridRequiresRenewalNoticesId}
        refreshGridIds={[gridRequiresRenewalNoticesId]}
      />,
      <CCNavButton title="Tools" type="more">
        <PermitIssueRenewalNoticeButton
          isDisabled={!checkPermissions(FormAction.LLP_ISSUERENEWALNOTICE)}
          productType={PRODUCT_TYPE_NUMBER.LLPermits}
          recordType={RECORDTYPE.LLP_Permit}
          dataSetFilter={MAILMERGEDATASET.LLP_Permit}
          gridId={gridRequiresRenewalNoticesId}
        />
        <PermitResetRenewalFeeFlagButton
          isDisabled={!checkPermissions(FormAction.LLP_RESETRENEWALFEEFLAG)}
          gridId={gridRequiresRenewalNoticesId}
        />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={LOCAL_LAWS_PERMIT_ROUTE}
        productType={PRODUCT_TYPE.LLPermits}
        recordType={RECORDTYPE.LLP_Permit}
        detail={listViewDisplayURL}
        displayName={recordDisplayURL}
        listViewDetail={listViewDisplayTitle}
        listViewDisplayName={listViewDisplayURL}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <PermitDetailsTab />,
      },
      {
        title: "Map",
        component: (
          <MapTab
            addressField={[nameOf("SiteAddress"), nameOf("Locality")]}
            zoom={18}
            recordType={RECORDTYPE.LLP_Permit}
            isInManagePage={false}
          />
        ),
      },
      { title: "Contacts", component: <PermitContactsTab /> },
      { title: "History", component: <PermitHistoryTab /> },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridRequiresRenewalNoticesId}
        dataUrl={getLocalLawRenewalsListViewOdata(
          PermitRenewalsTypes.PermitsRenewalsRequiresNotice
        )}
        columnFields={colLocalLawsRenewals}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
};

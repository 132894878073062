import { optionPermitType } from "@app/products/local-laws/[id]/components/general/components/form-element/components/permit-type-section/config";
import {
  IHandlerEventInitialData,
  Permit,
  PermitRegisterHandlerRequest,
  PermitRegisterUIControl,
  PermitUpdateTriggers,
  Svc_FormAction_PermitRegister,
  Svc_Permit,
} from "@app/products/local-laws/[id]/model";
import { Svc_LLPermitType } from "@app/products/local-laws/system-admin/permit-types/model";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";

interface IPermitTypeSectionProps {
  formRenderProps: FormRenderProps;
}
const nameOfSvcPermit = nameOfFactory<Svc_Permit>();
const nameOfPermit = nameOfFactory<Permit>();
const getNameOf = (permitPropsName: keyof Permit) => {
  return `${nameOfSvcPermit("Permit")}.${nameOfPermit(permitPropsName)}`;
};

export const PermitTypeSection = observer(
  ({ formRenderProps }: IPermitTypeSectionProps) => {
    //#region STORE ========/
    const { setInitialDataForms, dataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const uiControlStore =
      dataForms?.GeneralUIControl as PermitRegisterUIControl;
    //#endregion STORE =====/

    //#region REF ========/
    const prevPreferredContactDisplayName = useRef("");
    const prevSupervisorContactDisplayName = useRef("");
    //#endregion REF =====/

    const { valueGetter, onChange } = formRenderProps;
    const getValue = (name: keyof Permit) => valueGetter(getNameOf(name));
    const permitObj = valueGetter(nameOfSvcPermit("Permit")) as Permit;

    //#region HANDLER ========/
    const pickPermitTypeHandler = (permitType: Svc_LLPermitType) => {
      if (permitType) {
        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickPermitType,
          Permit: permitObj,
          PermitRegisterArgs: permitType?.ID ? [permitType?.ID] : null,
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select permit type failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("PermitType"), { value: null });
        onChange(getNameOf("PermitType_ID"), { value: 0 });
        onChange(getNameOf("Officer_ID"), { value: 0 });
        onChange(getNameOf("Officer"), { value: null });
        const saveTriggers = valueGetter(getNameOf("_SaveTriggers")) ?? [];
        if (!saveTriggers.includes(PermitUpdateTriggers.UpdatePermitType)) {
          onChange(getNameOf("_SaveTriggers"), {
            value: [...saveTriggers, PermitUpdateTriggers.UpdatePermitType],
          });
        }
      }
    };

    const pickPreferredContactHandler = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      if (contact) {
        let preferredContactRID = 0;
        if (
          permitObj?.PreferredContact_RID &&
          permitObj?.PreferredContact?.Contact?.Contact_ID ===
            event?.value?.Contact_ID
        ) {
          preferredContactRID = permitObj?.PreferredContact_RID;
        }
        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickPreferredContact,
          Permit: permitObj,
          PermitRegisterArgs: {
            Contact: contact ?? null,
            RID: preferredContactRID,
          },
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select preferred contact failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("PreferredContact"), { value: null });
        onChange(getNameOf("PreferredContact_RID"), { value: 0 });
        const saveTriggers = valueGetter(getNameOf("_SaveTriggers")) ?? [];
        if (
          !saveTriggers.includes(PermitUpdateTriggers.UpdatePreferredContact)
        ) {
          onChange(getNameOf("_SaveTriggers"), {
            value: [
              ...saveTriggers,
              PermitUpdateTriggers.UpdatePreferredContact,
            ],
          });
        }
      }
    };

    const pickSupervisorContactHandler = (
      event: ComboBoxChangeEvent | null
    ) => {
      const contact = event?.value;
      if (contact) {
        let supervisorContactRID = 0;
        if (
          permitObj?.Supervisor_RID &&
          permitObj?.Supervisor?.Contact?.Contact_ID ===
            event?.value?.Contact_ID
        ) {
          supervisorContactRID = permitObj?.Supervisor_RID;
        }
        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickSupervisor,
          Permit: permitObj,
          PermitRegisterArgs: {
            Contact: contact ?? null,
            RID: supervisorContactRID,
          },
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select supervisor failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("Supervisor"), { value: null });
        onChange(getNameOf("Supervisor_RID"), { value: 0 });
        const saveTriggers = valueGetter(getNameOf("_SaveTriggers")) ?? [];
        if (!saveTriggers.includes(PermitUpdateTriggers.UpdateSupervisor)) {
          onChange(getNameOf("_SaveTriggers"), {
            value: [...saveTriggers, PermitUpdateTriggers.UpdateSupervisor],
          });
        }
      }
    };
    //#endregion HANDLER =====/

    //#region UI ========/
    const isShowPreferredContact = useMemo(
      () => permitObj?.PermitType?.Flag_IsPreferredContactEnabled ?? false,
      [permitObj?.PermitType?.Flag_IsPreferredContactEnabled]
    );

    const isShowSupervisorContact = useMemo(
      () => permitObj?.PermitType?.Flag_IsSupervisorEnabled ?? false,
      [permitObj?.PermitType?.Flag_IsSupervisorEnabled]
    );

    const removeDisplayValueSupervisor = () => {
      if (uiControlStore) {
        prevSupervisorContactDisplayName.current =
          uiControlStore.LitSupervisor.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitSupervisor: {
              ...uiControlStore.LitSupervisor,
              Value: permitObj?.Supervisor.Contact.DisplayName,
            },
          },
        });
      }
    };

    const restoreDisplayValueSupervisor = () => {
      if (
        uiControlStore &&
        isHTML(prevSupervisorContactDisplayName.current) &&
        valueGetter(`${getNameOf("Supervisor")}.Contact`)
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitSupervisor: {
              ...uiControlStore.LitSupervisor,
              Value: prevSupervisorContactDisplayName.current,
            },
          },
        });
      }
    };

    const removeDisplayValuePreferredContact = () => {
      if (uiControlStore) {
        prevPreferredContactDisplayName.current =
          uiControlStore.LitPreferredContact.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitPreferredContact: {
              ...uiControlStore.LitPreferredContact,
              Value: permitObj?.PreferredContact.Contact.DisplayName,
            },
          },
        });
      }
    };

    const restoreDisplayValuePreferredContact = () => {
      if (
        uiControlStore &&
        isHTML(prevPreferredContactDisplayName.current) &&
        valueGetter(`${getNameOf("PreferredContact")}.Contact`)
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitPreferredContact: {
              ...uiControlStore.LitPreferredContact,
              Value: prevPreferredContactDisplayName.current,
            },
          },
        });
      }
    };

    //#endregion UI =====/

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Permit type" isMandatory />
            <Field
              name={getNameOf("PermitType")}
              nameDisplay="Name"
              placeholder="Select permit type"
              component={InputPickerSearch}
              valueMapGrid={getValue("PermitType_ID")}
              onChange={pickPermitTypeHandler}
              options={optionPermitType}
              value={getValue("PermitType")}
              validator={requiredValidator}
              isLazyLoadOData
            />
          </div>
          {isShowPreferredContact && (
            <div className="cc-field">
              <CCLabel title="Contact" />
              <Field
                uniqueKey="PermitPreferredContact"
                name={`${getNameOf("PreferredContact")}.Contact`}
                component={ContactPicker}
                onChange={pickPreferredContactHandler}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Pick contact errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                placeholder={"Select contact"}
                displayValue={uiControlStore?.LitPreferredContact.Value}
                removeDisplayValue={removeDisplayValuePreferredContact}
                restoreDisplayValue={restoreDisplayValuePreferredContact}
              />
            </div>
          )}
          {isShowSupervisorContact && (
            <div className="cc-field">
              <CCLabel title="Supervisor" />
              <Field
                uniqueKey="PermitSupervisorContact"
                name={`${getNameOf("Supervisor")}.Contact`}
                component={ContactPicker}
                onChange={pickSupervisorContactHandler}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Pick contact errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                placeholder={"Select supervisor"}
                displayValue={uiControlStore?.LitSupervisor.Value}
                removeDisplayValue={removeDisplayValueSupervisor}
                restoreDisplayValue={restoreDisplayValueSupervisor}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);

import { AddAdditionalAddressButton } from "@app/core/addresses/components/buttons/add-additional-address/_index";
import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AddComplaintEvent } from "@app/core/complaints/_index";
import { AddConditionWithParentButton } from "@app/core/condition/components/buttons/add-condition/_index";
import { AddStdConditionButton } from "@app/core/condition/components/buttons/add-standard-condition/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { AddInspectionsWithParentButton } from "@app/core/inspections/inspections-button/_index";
import { AddInvoiceWithParentButton } from "@app/core/invoice/invoice-button/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { AddNoticeWithParentButton } from "@app/core/notices/notices-button/_index";
import { AddRelatedButton } from "@app/core/related/related-button/_index";
import { ViewOnMapByGISButton } from "@app/core/view-on-map/components/buttons/view-map-by-gis/_index";
import { Svc_ViewOnMap } from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { SavePermitButton } from "@app/products/local-laws/[id]/components/buttons/save/_index";
import { AccordionsLocalLawsPermit } from "@app/products/local-laws/[id]/components/child-screen/_index";
import { PermitForm } from "@app/products/local-laws/[id]/components/general/_index";
import { LocalLawPermitFormTitle } from "@app/products/local-laws/[id]/constant";
import { Permit, Permit_Status } from "@app/products/local-laws/[id]/model";
import {
  getPermitStatusName,
  renderBubbleUps,
} from "@app/products/local-laws/[id]/util";
import { PermitContactsTab } from "@app/products/local-laws/components/sidebar/contacts/_index";
import { PermitDetailsTab } from "@app/products/local-laws/components/sidebar/details/_index";
import { PermitHistoryTab } from "@app/products/local-laws/components/sidebar/history/_index";
import { DeactivatePermitButton } from "@app/products/local-laws/components/toolbar/buttons/deactivate/_index";
import { IssuePermitButton } from "@app/products/local-laws/components/toolbar/buttons/issue-permit/_index";
import { LodgePermitButton } from "@app/products/local-laws/components/toolbar/buttons/lodge-permit/_index";
import { ReactivatePermitButton } from "@app/products/local-laws/components/toolbar/buttons/reactivate/_index";
import { RefusePermitButton } from "@app/products/local-laws/components/toolbar/buttons/refuse-permit/_index";
import { RenewPermitButton } from "@app/products/local-laws/components/toolbar/buttons/renew/_index";
import { SameApplicationPermitButton } from "@app/products/local-laws/components/toolbar/buttons/same-application/_index";
import { WithdrawPermitButton } from "@app/products/local-laws/components/toolbar/buttons/withdraw/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { observer } from "mobx-react-lite";
import { default as React, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const ExistManagePermit = observer(() => {
  //#region STORE ========/
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  const permitObj = dataForms?.GeneralForm?.Permit as Permit;

  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });
  //#endregion STORE =====/

  //#region STATE ========/
  const [showSlideBar, setShowSlideBar] = useState(true);
  //#endregion STATE =====/

  //#region HOOK ========/
  const location = useLocation();
  //#endregion HOOK =====/

  const permitID = permitObj?.Permit_ID;

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Permit - ${permitObj?.Permit_ID ?? 0}`,
      Text: `${permitObj?.PermitType?.Name}`,
      LinkUrl: location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.LLPermits,
      RecordType_ENUM: RECORDTYPE.LLP_Permit,
      Record_ID: permitObj?.Permit_ID ?? 0,
    },
  ];

  const allowSavePermission = checkPermissions([
    FormAction.CORE_ALLOW_SAVE,
    FormAction.CORE_ALLOW_EDIT,
  ]);

  const MemoizedWorkflowPermitButton = useMemo(() => {
    return (
      <>
        <IssuePermitButton isDisabled={!allowSavePermission} />
        <LodgePermitButton isDisabled={!allowSavePermission} />
        <RefusePermitButton isDisabled={!allowSavePermission} />
        <WithdrawPermitButton isDisabled={!allowSavePermission} />
        <RenewPermitButton isDisabled={!allowSavePermission} />
        <DeactivatePermitButton isDisabled={!allowSavePermission} />
        <ReactivatePermitButton isDisabled={!allowSavePermission} />
      </>
    );
  }, [allowSavePermission]);

  const getPermitFormBadge = () => {
    let badge = [getPermitStatusName(permitObj?.Status_ENUM)];
    if (
      permitObj?.DueStatus &&
      permitObj?.Status_ENUM === Permit_Status.PermitLodged
    ) {
      badge.push(permitObj?.DueStatus);
    }
    return badge;
  };

  return (
    <>
      <FormTitle
        title={`${LocalLawPermitFormTitle} - ${permitObj?.Permit_Number}`}
        badge={getPermitFormBadge()}
      />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            {MemoizedWorkflowPermitButton}
          </CCNavButton>,
          <SavePermitButton isDisabled={!allowSavePermission} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionWithParentButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              bubbleUps={renderBubbleUps(permitID, RECORDTYPE.LLP_Permit)}
              disabled={!checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)}
            />
            <AddAdditionalAddressButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
            />
            <AdditionalContactButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              disabled={!checkPermissions(FormAction.CORE_TOOLBAR_ADD_CONTACT)}
            />
            <AddAttachmentButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
              }
            />
            <AddCommentButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
              }
            />
            <CommunicationButtonListView
              productType={PRODUCT_TYPE_NUMBER.LLPermits}
              recordType={RECORDTYPE.LLP_Permit}
              recordId={permitID}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
              }
            />
            <AddComplaintEvent
              recordType={RECORDTYPE.LLP_Permit}
              parentId={permitID}
              parentName={permitObj?.PermitModel_Name}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMPLAINT)
              }
            />
            <AddConditionWithParentButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              productType={PRODUCT_TYPE_NUMBER.LLPermits}
              bubbleUps={renderBubbleUps(permitID, RECORDTYPE.LLP_Permit)}
              isCondition
            />
            <AddDocumentButton
              dataSetFilter={MAILMERGEDATASET?.LLP_Permit}
              recordType={RECORDTYPE.LLP_Permit}
              productType={PRODUCT_TYPE_NUMBER.CustomerService}
              id={permitID}
              bubbleUps={renderBubbleUps(permitID, RECORDTYPE.LLP_Permit)}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
              }
            />
            <AddInspectionsWithParentButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              bubbleUps={renderBubbleUps(permitID, RECORDTYPE.LLP_Permit)}
              productType={PRODUCT_TYPE_NUMBER.LLPermits}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_INSPECTION)
              }
            />
            <AddInvoiceWithParentButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              bubbleUps={renderBubbleUps(permitID, RECORDTYPE.LLP_Permit)}
              productType={PRODUCT_TYPE_NUMBER.LLPermits}
            />
            <AddNoticeWithParentButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
              bubbleUps={renderBubbleUps(permitID, RECORDTYPE.LLP_Permit)}
              productType={PRODUCT_TYPE_NUMBER.LLPermits}
              isDisabled={!checkPermissions(FormAction.CORE_TOOLBAR_ADD_NOTICE)}
            />
            <AddRelatedButton
              id={permitID}
              recordType={RECORDTYPE.LLP_Permit}
            />
            <AddStdConditionButton
              productType={PRODUCT_TYPE_NUMBER.LLPermits}
              recordType={RECORDTYPE.LLP_Permit}
              recordID={permitID}
            />
          </CCNavButton>,
          <CCNavButton title="Map" type="sub">
            <ViewOnMapByGISButton
              objViewOnMapRequest={
                {
                  ReturnControllName: "GISKey",
                  GISKey: permitObj?.Address?.AssessmentNo ?? "",
                  ObjAddress: permitObj?.Address,
                  Module: "",
                  Layer: "",
                  ProductType: ProductType.LLPermits,
                } as unknown as Svc_ViewOnMap
              }
              isInManagePage
            />
          </CCNavButton>,
          <CCNavButton title="Tools" type="more">
            <SameApplicationPermitButton />
          </CCNavButton>,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {permitObj && <PermitForm />}
            <AccordionsLocalLawsPermit />
          </div>
        </div>
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "Details",
                  component: <PermitDetailsTab />,
                },
                {
                  title: "Map",
                  component: (
                    <MapTab
                      address={permitObj?.Address?.Formatted_SingleLine}
                      zoom={18}
                      objViewOnMapRequest={
                        {
                          ReturnControllName: "GISKey",
                          GISKey: permitObj?.Address?.AssessmentNo ?? "",
                          ObjAddress: permitObj?.Address,
                          Module: "",
                          Layer: "",
                          ProductType: ProductType.LLPermits,
                        } as Svc_ViewOnMap
                      }
                      isInManagePage
                      isDisabled={
                        !checkPermissions(FormAction.CRMS_TOOLS_VIEWONMAP)
                      }
                    />
                  ),
                },
                {
                  title: "Contact",
                  component: <PermitContactsTab />,
                },
                {
                  title: "History",
                  component: <PermitHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
